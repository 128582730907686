import { api } from '../../../services/api';
import { portalUserService } from '../../../services';
import { DashboardDataPayload } from './DashboardDataPayload';
import { AxiosResponse } from 'axios';
import { DateRangePickerShortcut } from '../../../ui/DateRangePicker/DateRangePicker.utils';

export interface StraightThroughProcessingReport {
    storedProcedure: 'DashboardReportWorkflowDocumentsStraightThruProcessingCounts';
    viewModel: {
        CountWithNoUserTasks: number;
        Date: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'Date';
    };
}

export interface InvoicesRightFirstTimeDashboardsReportDonut {
    storedProcedure: 'DashboardReportInvoicesRightFirstTimeDonut';
    viewModel: {
        PercentRightFirstTime: number | null;
        PercentRejected: number | null;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: undefined;
    };
}

export interface StraightThroughAutoGLCodingReport {
    storedProcedure: 'DashboardReportStraightThroughAutoGLCoding';
    viewModel: {
        Count: number;
        Date: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'Date';
    };
}

export interface InvoicesRightFirstTimeDashboardsReportLine {
    storedProcedure: 'DashboardReportInvoicesRightFirstTimeLine';
    viewModel: {
        PercentRightFirstTime: number;
        Date: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'Date';
    };
}

export interface StraightThruBreakdownReport {
    storedProcedure: 'DashboardReportStraightThruBreakdown';
    viewModel: {
        Date: string;
        POCMPercent: number;
        AutoRoutedPercent: number;
        AutoGLCodingPercent: number;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'Date';
    };
}

export interface StraightThroughPOMatchDashboardReport {
    storedProcedure: 'DashboardReportStraightThroughPOMatch';
    viewModel: {
        Date: string;
        Count: number;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'Date';
    };
}
export interface StraightThruAutoRoutingReport {
    storedProcedure: 'DashboardReportWorkflowAutoRouted';
    viewModel: {
        Date: string;
        AutoRoutedCount: number;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'Date';
    };
}

export interface BuyerSupplierConnectedDashboardReport {
    storedProcedure: 'DashboardReportBuyerSupplierConnected';
    viewModel: {
        SuppliersConnected: number;
        Date: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'Date';
    };
}
export interface SuppliersReport {
    storedProcedure: 'DashboardReportSuppliersSending';
    viewModel: {
        ActiveSupplierCount: number;
        Date: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'Date';
    };
}
export interface WorkflowDocument {
    storedProcedure: 'DashboardReportWorkflowDocuments';
    viewModel: {
        WorkflowDocumentCount: number;
        Date: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'Date';
    };
}

export interface InvoicesDelivered {
    storedProcedure: 'DashboardReportInvoicesDelivered';
    viewModel: {
        InvoicesDeliveredCount: number;
        Date: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'Date';
    };
}

export interface TopSupplierRejections {
    storedProcedure: 'DashboardReportTopSupplierRejections';
    viewModel: {
        SupplierName: string;
        SupplierCompanyId: number;
        Reasons: string;
        NumRejections: number;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: undefined;
    };
}

export interface InvoicesRejectedDashboardReport {
    storedProcedure: 'DashboardReportInvoicesRejected';
    viewModel: {
        TotalRejections: number;
        Date: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'Date';
    };
}

export interface InvoicesRejected {
    storedProcedure: 'DashboardReportRejectionReason';
    viewModel: {
        NumRejections: number;
        Label: string;
        RejectionPercent: number;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: undefined;
    };
}

export interface TopSuppliers {
    storedProcedure: 'DashboardReportTopSuppliers';
    viewModel: {
        NumDocs: number;
        Name: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: undefined;
    };
}

export interface TopSuppliersWithoutStraightThroughProcessing {
    storedProcedure: 'DashboardReportTopSuppliersWithoutStraightThroughProcessing';
    viewModel: {
        NumDocsWithUserTasks: number;
        Name: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: undefined;
    };
}

export interface TopSuppliersWithStraightThroughProcessing {
    storedProcedure: 'DashboardReportTopSuppliersWithStraightThroughProcessing';
    viewModel: {
        NumNoUserTaskDocs: number;
        Name: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: undefined;
    };
}

export interface SpendInvoice {
    storedProcedure: 'DashboardReportInvoiceSpend';
    viewModel: {
        SpendAmount: number;
        Date: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'Date';
    };
}

export interface SpendPayments {
    storedProcedure: 'DashboardReportInvoicePayments';
    viewModel: {
        Count: number;
        PaymentStatus: string;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: 'PaymentStatus';
    };
}

export interface SupplierTotal {
    storedProcedure: 'DashboardReportSupplierTotal';
    viewModel: {
        SupplierTotal: number;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: undefined;
    };
}

export interface InvoiceTotal {
    storedProcedure: 'DashboardReportInvoiceTotal';
    viewModel: {
        InvoiceTotal: number;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: undefined;
    };
}

export interface SpendTotal {
    storedProcedure: 'DashboardReportSpendTotal';
    viewModel: {
        SpendTotal: number;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: undefined;
    };
}

export interface POTotal {
    storedProcedure: 'DashboardReportPOTotal';
    viewModel: {
        POTotal: number;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: undefined;
    };
}

export interface POATotal {
    storedProcedure: 'DashboardReportPOATotal';
    viewModel: {
        POATotal: number;
    }[];
    nullifyDataIfEmpty: {
        optIn: boolean;
        xAxisPropertyKey: undefined;
    };
}

export type DashboardReport =
    | StraightThroughPOMatchDashboardReport
    | StraightThruAutoRoutingReport
    | BuyerSupplierConnectedDashboardReport
    | StraightThruBreakdownReport
    | WorkflowDocument
    | InvoicesDelivered
    | TopSupplierRejections
    | SuppliersReport
    | TopSuppliers
    | TopSuppliersWithoutStraightThroughProcessing
    | TopSuppliersWithStraightThroughProcessing
    | InvoicesRejected
    | InvoicesRightFirstTimeDashboardsReportLine
    | InvoicesRightFirstTimeDashboardsReportDonut
    | StraightThroughProcessingReport
    | StraightThroughAutoGLCodingReport
    | SpendInvoice
    | SpendPayments
    | SupplierTotal
    | InvoiceTotal
    | SpendTotal
    | POTotal
    | POATotal
    | InvoicesRejectedDashboardReport;

export type ChartConfiguration = {
    dateRange: {
        startDate?: Date | null;
        endDate?: Date | null;
    } | null;
    shortcut: DateRangePickerShortcut | null; // DatePicker shortcut value
    timePeriodGrouping: string | null;
};

export type DashboardSettings = {
    storedProcedure: string;
    settings: ChartConfiguration;
};

export class DashboardApi {
    private route = '/Report';

    public getDashboardReportData<T extends DashboardReport>(
        settings: DashboardDataPayload,
        storedProcedure: T['storedProcedure']
    ): Promise<AxiosResponse<T['viewModel']>> {
        const CompanyId = portalUserService.getCurrentCompanyId();

        const payload = {
            Settings: {
                EndDate: settings?.EndDate,
                StartDate: settings?.StartDate,
                Interval: settings.Interval ?? null,
            },
            CompanyId,
            StoredProcedureName: storedProcedure,
        };
        return api().post(`${this.route}/RunDashboardReport`, payload);
    }

    public exportDashboardReport(settings: DashboardDataPayload, storedProcedure: string) {
        const CompanyId = portalUserService.getCurrentCompanyId();

        const payload = {
            Settings: {
                EndDate: settings?.EndDate,
                StartDate: settings?.StartDate,
                Interval: settings.Interval ?? null,
            },
            CompanyId,
            StoredProcedureName: storedProcedure,
        } as DashboardDataPayload;

        return api().post(`${this.route}/ExportDashboardReport`, payload, {
            headers: {
                Accept: 'text/csv',
            },
        });
    }
}
